import React, { useState, useRef, useEffect } from "react";

const Contador = (props) => {

  let fecha = props.fecha;

  const parts = props.fecha.split("/");

  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months in JavaScript are zero-based (0-11)
  const year = parseInt(parts[2], 10);

  let fecha_referencia = new Date(year, month, day);

  fecha = props.meses_dias ? fecha : year;

  console.log(fecha_referencia.toISOString());

  function getDateDifference(desde, hasta, meses_dias) {

    let difference = hasta - desde;

    if (difference < 0) {
      // Swap the values of hasta and desde
      const temp = hasta;
      hasta = desde;
      desde = temp;
    }

    difference = hasta - desde;


    const yearDiff = hasta.getFullYear() - desde.getFullYear();
    const monthDiff = hasta.getMonth() - desde.getMonth();
    const dayDiff = hasta.getDate() - desde.getDate();

    let years = yearDiff;
    let months = monthDiff;
    let days = dayDiff;

    if (dayDiff < 0) {
      months -= 1;
      const lastMonth = new Date(
        hasta.getFullYear(),
        hasta.getMonth(),
        0
      ).getDate();
      days = lastMonth - desde.getDate() + hasta.getDate();
    }

    if (monthDiff < 0) {
      years -= 1;
      months = 12 + monthDiff;
    }

    // return { years, months, days };

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        Años: years,
        ...(props.meses_dias ? { Meses: months, Días: days } : {}),
      };
    }

    return timeLeft;
  }

  const [timeLeft, setTimeLeft] = useState(
    getDateDifference(fecha_referencia, new Date())
  );

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setTimeLeft(getDateDifference(props.fecha, new Date()));
  //   }, []);

  //   return () => clearTimeout(timer);
  // });

  // setTimeLeft(getDateDifference(props.fecha, new Date()));

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div
      className="alert alert-info m-1 etiqueta-info"
      style={{ padding: "3px", width: "100%" }}
    >
      {props.nombre}: ({fecha}) {timerComponents.length ? timerComponents : <span>Xa pasou</span>}
    </div>
  );
};

export default Contador;

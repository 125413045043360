import React from "react";
import "./App.css";
import Mantenimiento from "./Components/Mantenimiento";
import Contador from "./Components/Contador";

function App() {
  const vehiculos = [
    {
      nombre: "Rojelio",
      matricula: "4223FFG",
      ano: {
        nombre: "Año",
        fecha: "01/01/2004",
      },
      mantenimientos: {
        aceite: {
          nombre: "Aceite",
          // km_ultimo: "1234",
          // fecha_ultimo: "05/06/1977",
          // km_siguiente: "1234",
          // notas: "Cada x km",
        },
        correa: {
          nombre: "Correa",
          // km_ultimo: "1234",
          // fecha_ultimo: "05/06/1977",
          // km_siguiente: "1234",
          // notas: "Cada x km",
        },
      },
    },
    {
      nombre: "Gris",
      matricula: "1190DPD",
      ano: {
        nombre: "Año",
        fecha: "01/01/2007",
      },
      mantenimientos: {
        revision: {
          nombre: "Revisión",
          km_anterior: "185000",
          km_siguiente: "207000",
        },
        aceite: {
          nombre: "Aceite",
          // km_ultimo: "1234",
          // fecha_ultimo: "05/06/1977",
          // km_siguiente: "1234",
          // notas: "Cada x km",
        },
        correa: {
          nombre: "Correa",
          km_ultimo: "200000",
          // fecha_ultimo: "05/06/1977",
          // km_siguiente: "1234",
          notas: "Cada x km.",
          "200k": "Correa + Correa alternador + Bomba de agua",
        },
      },
    },
    {
      nombre: "Piruleira II",
      matricula: "------",
      ano: {
        nombre: "Año",
        fecha: "01/01/2007",
      },
      mantenimientos: {
        revision: {
          nombre: "Revisión",
          // km_siguiente: "207000",
        },
        aceite: {
          nombre: "Aceite",
          // km_ultimo: "1234",
          // fecha_ultimo: "05/06/1977",
          // km_siguiente: "1234",
          // notas: "Cada x km",
        },
        correa: {
          nombre: "Correa",
          // km_ultimo: "1234",
          // fecha_ultimo: "05/06/1977",
          km_siguiente: "200000",
          // notas: "Cada x km",
        },
      },
    },
  ];

  const fechas_eventos = {
    resu: { name: "Resurrection Fest:", fecha: "26/06/2024" },
  };

  return (
    <div className="App">
      Alumentum V0.0.1 <br></br>
      {vehiculos.map((vehiculo, index) => (
        <div key={index}>
          <h2>
            .:{vehiculo.nombre} - {vehiculo.matricula}:.
          </h2>
          <Contador
            key={vehiculo.nombre}
            nombre={vehiculo.ano.nombre}
            fecha={vehiculo.ano.fecha}
            meses_dias={false}
          />

          {Object.keys(vehiculo.mantenimientos).map((key) => (
            <div key={key}>
              {vehiculo.mantenimientos[key].nombre}____________________________
              {Object.keys(vehiculo.mantenimientos[key]).map(
                (key2) =>
                  key2 !== "nombre" && (
                    <div
                      className="alert alert-info m-1 etiqueta-info"
                      style={{ padding: "3px", width: "100%" }}
                    >
                      {key2}: {vehiculo.mantenimientos[key][key2]}
                    </div>
                  )
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default App;
